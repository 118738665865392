// cartReducer.js

import { createSlice } from "@reduxjs/toolkit";

export const CartSlice = createSlice({
    name: "cart",
    initialState: {
        cart: [],
    },
    reducers: {
        addToCart: (state, action) => {
            const { service, articals } = action.payload;

            // Find the index of the service in the cart
            const existingServiceIndex = state.cart.findIndex((s) => s.service.id === service.id);

            if (existingServiceIndex !== -1) {
                state.cart[existingServiceIndex].articals = state.cart[existingServiceIndex].articals.filter(
                    (item) => !(Object.keys(item).length === 1 && item.hasOwnProperty('quantity')) && item.id !== 0
                );

                const existingArticleIndex = state.cart[existingServiceIndex].articals.findIndex((item) => item.id === articals.id);
                if (existingArticleIndex !== -1) {
                    state.cart[existingServiceIndex].articals[existingArticleIndex].quantity++;
                } else {
                    state.cart[existingServiceIndex].articals.push({ ...articals, quantity: 1 });
                }
            } else {
                state.cart.push({ service, articals: [{ ...articals,id:0,quantity: 1,price:20,name:'Save my time' }] });
            }
        },

        removeFromCart: (state, action) => {
            const { service } = action.payload;
            state.cart = state.cart.filter((item) => item.service.id !== service.id);
        },
        incrementQuantity: (state, action) => {
            const { service, articals } = action.payload;
            const existingServiceIndex = state.cart.findIndex((s) => s.service.id === service.id);
            if (existingServiceIndex !== -1) {
                const existingArticleIndex = state.cart[existingServiceIndex].articals.findIndex((item) => item.id === articals.id);
                if (existingArticleIndex !== -1) {
                    state.cart[existingServiceIndex].articals[existingArticleIndex].quantity++;
                }
            }
        },
        emptyCart: (state, action) => {
            state.cart = [];
        },
        decrementQuantity: (state, action) => {
            const { service, articals } = action.payload;
            const existingServiceIndex = state.cart.findIndex((s) => s.service.id === service.id);
            if (existingServiceIndex !== -1) {
                const existingArticleIndex = state.cart[existingServiceIndex].articals.findIndex((item) => item.id === articals.id);
                if (existingArticleIndex !== -1) {
                    state.cart[existingServiceIndex].articals[existingArticleIndex].quantity--;
                    if (state.cart[existingServiceIndex].articals[existingArticleIndex].quantity === 0) {
                        state.cart[existingServiceIndex].articals.splice(existingArticleIndex, 1);
                    }
                    if (state.cart[existingServiceIndex].articals.length === 0) {
                        state.cart.splice(existingServiceIndex, 1);
                    }
                }
            }
        },

    }
});

export const { addToCart, removeFromCart, incrementQuantity, decrementQuantity, emptyCart } = CartSlice.actions;
export default CartSlice.reducer;
