import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://webapi.laundramoon.com";

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    const token = getState()?.auth?.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("MobileToken", "1234565");
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    getServices: builder.query({
      query: (data) => {
        const queryString = Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join("&");
        return `api/get-services?${queryString}`;
      },
    }),
    allSerivesByArticals: builder.query({
      query: (data) => {
        const queryString = Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join("&");
        return `api/getServicesArticalsAndPrice?${queryString}`;
      },
    }),
    getArticalByServices: builder.query({
      query: (data) => {
        const queryString = Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join("&");
        return `api/get-articals-by-services?${queryString}`;
      },
    }),
    createOrder: builder.mutation({
      query: (newOrder) => ({
        url: "api/create-order-app",
        method: "POST",
        body: newOrder,
      }),
    }),
    getOrder: builder.query({
      query: (data) => {
        const queryString = Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join("&");
        return `web/my-order?${queryString}`;
      },
    }),
    createPaymentIntent: builder.mutation({
      query: (data) => ({
        url: "api/stripe-charge",
        method: "POST",
        body: data,
      }),
    }),
    updatePaymentIntent: builder.mutation({
      query: (updateIntent) => ({
        url: "api/update-paymentIntent",
        method: "POST",
        body: updateIntent,
      }),
    }),
    loginApi: builder.mutation({
      query: (Loginform) => ({
        url: "api/login",
        method: "POST",
        body: Loginform,
      }),
    }),
    socialLoginApi: builder.mutation({
      query: (socialLogin) => ({
        url: "api/socialLogin",
        method: "POST",
        body: socialLogin,
      }),
    }),
    guestLoginApi: builder.mutation({
      query: (guestLogin) => ({
        url: "api/guestLogin",
        method: "POST",
        body: guestLogin,
      }),
    }),
    registerApi: builder.mutation({
      query: (Registerform) => ({
        url: "api/register",
        method: "POST",
        body: Registerform,
      }),
    }),
    getUserNotification: builder.query({
      query: (data) => {
        const queryString = Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join("&");
        return `api/my-notification?${queryString}`;
      },
    }),
    updateProfile: builder.mutation({
      query: (Registerform) => ({
        url: "api/profile",
        method: "POST",
        body: Registerform,
      }),
    }),
    getAddress: builder.query({
      query: (data) => {
        const queryString = Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join("&");
        return `api/addresses?${queryString}`;
      },
    }),
    updateAddressApi: builder.mutation({
      query: (updateAddress) => ({
        url: "api/addresses/update",
        method: "POST",
        body: updateAddress,
      }),
    }),
    addAddressApi: builder.mutation({
      query: (addAddress) => ({
        url: "api/addresses/add",
        method: "POST",
        body: addAddress,
      }),
    }),
    errorTracingApi: builder.mutation({
      query: (errorPayload) => ({
        url: "api/errorHandling",
        method: "POST",
        body: errorPayload,
      }),
    }),
    deleteAccountApi: builder.mutation({
      query: (deleteApi) => ({
        url: "api/user-delete",
        method: "POST",
        body: deleteApi,
      }),
    }),
  }),
});

export const { useGetServicesQuery, useAllSerivesByArticalsQuery, useGetArticalByServicesQuery, useCreateOrderMutation, useGetOrderQuery, useCreatePaymentIntentMutation, useUpdatePaymentIntentMutation, useLoginApiMutation, useSocialLoginApiMutation, useGuestLoginApiMutation, useRegisterApiMutation, useGetUserNotificationQuery, useUpdateProfileMutation, useGetAddressQuery, useUpdateAddressApiMutation, useAddAddressApiMutation, useErrorTracingApiMutation, useDeleteAccountApiMutation } = apiSlice;
